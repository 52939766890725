import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { UserDto } from 'src/app/core/model/user.model';
import { ReturnUrlService } from '../return-url/return-url.service';
import { environment } from 'src/environments/environment';
import { InvitedUser } from 'src/app/core/interfaces/user.interface';
import {
  COMPANY_ID,
  AUTHORIZATION_HEADER,
  INV_TOKEN,
  TOKEN,
  BEARER_TOKEN,
  ADMIN,
  COMPANIES,
} from 'src/app/core/constants/auth.constants';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private http = inject(HttpClient);
  private returnUrlService = inject(ReturnUrlService);
  private defaultView = inject(DOCUMENT).defaultView;

  #jwtToken: string | undefined | null;

  login(user: UserDto): Observable<any> {
    return this.http
      .post(
        environment.apiUrl,
        {
          ...user,
          company_id: this.getSearchParam(COMPANY_ID),
        },
        { observe: 'response' }
      )
      .pipe(
        tap((response) => {
          this.#jwtToken = response.headers.get(AUTHORIZATION_HEADER);
          if (this.#jwtToken) {
            this.redirectUserByReturnUrl();
          }
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      );
  }

  register(form: InvitedUser) {
    const companyName = this.getSearchParam(COMPANY_ID);
    const invitationToken = this.getSearchParam(INV_TOKEN);

    return this.http
      .put(environment.invitationApiUrl, {
        password: form.password,
        password_confirmation: form.confirmPassword,
        invitation_token: invitationToken,
        company_id: companyName,
      })
      .pipe(
        tap(() => {
          if(this.defaultView && companyName) {
            this.defaultView.location.replace(
              `/login?${COMPANY_ID}=${companyName}`
            );
          } else {
            this.defaultView &&
              this.defaultView.location.replace(
                `/${ADMIN}/${COMPANIES}`
              );
          }
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      );
  }

  setTokenFromLocalStorage() {
    this.#jwtToken = this.getJwtTokenFromLocalStorage();
  }

  redirectUserByReturnUrl(): void {
    if (this.returnUrlService.returnUrl && this.defaultView) {
      const url = new URL(this.returnUrlService.returnUrl);
      url.searchParams.set(TOKEN, this.#jwtToken as string);
      url.searchParams.set(COMPANY_ID, this.getSearchParam(COMPANY_ID));
      this.defaultView.location.replace(url);
    }
  }

  isEqualCompanies() {
    const savedCompany = localStorage.getItem(COMPANY_ID);
    const comparedCompany = this.getSearchParam(COMPANY_ID);

    return savedCompany && comparedCompany && savedCompany === comparedCompany;
  }

  private getJwtTokenFromLocalStorage() {
    return localStorage.getItem(BEARER_TOKEN);
  }

  private getSearchParam(param: typeof COMPANY_ID | typeof INV_TOKEN) {
    return (
      new URLSearchParams(new URL(window.location.href).search).get(param) || ''
    );
  }
}
