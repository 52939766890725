import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-header-caption',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './header-caption.component.html',
  styleUrls: ['./header-caption.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderCaptionComponent {
  @Input()
  text: string = '';
}
