<form [formGroup]="form">
  <app-text-field [name]="'pass'"
                  formControlName="password"
                  [type]="'password'"
                  [label]="'form.create_password'"
                  validationMessage
                  [showPasswordButton]="true"></app-text-field>
  <app-text-field [name]="'pass-confirm'"
                  formControlName="confirmPassword"
                  [type]="'password'"
                  [label]="'form.confirm_password'"
                  validationMessage
                  [showPasswordButton]="true"></app-text-field>
</form>

<div class="chips">
  <div [ngClass]="{'chips-item-ok': !(form | errors:'characters')}"
       class="chips-item">
    <span class="ok-icon"></span>
    <span class="text">{{'form.chips.characters' | translate }}</span>
  </div>
  <div [ngClass]="{'chips-item-ok': !(form | errors:'capitalLetter')}"
       class="chips-item">
    <span class="ok-icon"></span>
    <span class="text">{{'form.chips.capital_letter' | translate }}</span>
  </div>
  <div [ngClass]="{'chips-item-ok': !(form | errors:'lowercaseLetter')}"
       class="chips-item">
    <span class="ok-icon"></span>
    <span class="text">{{'form.chips.lowercase_letter' | translate }}</span>
  </div>
  <div [ngClass]="{'chips-item-ok': !(form | errors:'symbol')}"
       class="chips-item">
    <span class="ok-icon"></span>
    <span class="text">{{'form.chips.symbol' | translate }}</span>
  </div>
  <div [ngClass]="{'chips-item-ok': !(form | errors:'numbers')}"
       class="chips-item">
    <span class="ok-icon"></span>
    <span class="text">{{'form.chips.numbers' | translate }}</span>
  </div>
</div>

<button type="button"
        [disabled]="form.invalid"
        (click)="register()"
        class="btn">{{ 'signin' | translate }}</button>

<div class="failed-login">
  {{errorMessage}}
</div>
