import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PasswordForm } from 'src/app/core/interfaces/password-form.interface';
import { ErrorsPipe } from 'src/app/pipes/errors.pipe';
import { PasswordValidators } from 'src/app/validators/validator';
import { ValidationMessageDirective } from 'src/app/validators/validation-message/validation-message.directive';
import { TextFieldComponent } from '../controls/text-field/text-field.component';
import { firstValueFrom, tap } from 'rxjs';
import { LoginService } from 'src/app/services/login/login.service';
import { InvitedUser } from 'src/app/core/interfaces/user.interface';

@Component({
  selector: 'app-registration-form',
  standalone: true,
  imports: [
    CommonModule,
    TextFieldComponent,
    ReactiveFormsModule,
    ValidationMessageDirective,
    TranslateModule,
    ErrorsPipe,
  ],
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationFormComponent {
  errorMessage: string = '';
  form = new FormGroup<PasswordForm>(
    {
      password: new FormControl('', {
        nonNullable: true,
        validators: [PasswordValidators.validatePasswordField],
      }),
      confirmPassword: new FormControl('', {
        nonNullable: true,
        validators: [PasswordValidators.passwordMatch, Validators.required],
      }),
    },
    {
      validators: [
        PasswordValidators.passwordCharactersLength(8),
        PasswordValidators.passwordCapitalLetter,
        PasswordValidators.passwordLowercaseLetter,
        PasswordValidators.passwordSymbol,
        PasswordValidators.passwordNumbers,
      ],
      updateOn: 'change',
    }
  );

  private loginService = inject(LoginService);
  private cdr = inject(ChangeDetectorRef);

  async register(): Promise<void> {
    if (this.form.invalid) return;
    const form = this.form.getRawValue() as InvitedUser;

    await firstValueFrom(
      this.loginService.register(form).pipe(
        tap((msg) => {
          this.errorMessage = msg.error.error;
          this.cdr.markForCheck();
        })
      )
    );
  }
}
