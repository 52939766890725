import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  enableProdMode,
  importProvidersFrom,
} from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable, of } from 'rxjs';
import { AppComponent } from './app/app.component';
import { AuthComponent } from './app/core/interfaces/auth-component.interface';
import { PageStrategy } from './app/page-strategy';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function defineComponent(
  pageStrategy: PageStrategy,
): () => Observable<AuthComponent | undefined> {
  const urlSearchParams: URLSearchParams = new Proxy(
    new URLSearchParams(window.location.search),
    {
      get: (searchParams: URLSearchParams, prop: string) =>
        searchParams.get(prop),
    },
  );

  //@ts-ignore
  const component = urlSearchParams.invitationToken
    ? pageStrategy.get('register')
    : pageStrategy.get('signin');

  pageStrategy.component = component;

  return () => of(component);
}

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: defineComponent,
      deps: [PageStrategy],
    },
    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: 'en',
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
      HttpClientModule,
    ),
  ],
}).catch(err => console.error(err));
