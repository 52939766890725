import { Injectable } from '@angular/core';
import { AuthComponent } from './core/interfaces/auth-component.interface';
import { AuthStrategyType } from './core/types/auth-strategy.type';
import { AuthorizationComponent } from './pages/authorization/authorization.component';
import { RegistrationComponent } from './pages/registration/registration.component';

@Injectable({
  providedIn: 'root',
})
export class PageStrategy {
  list: Map<AuthStrategyType, AuthComponent> = new Map();
  component: AuthComponent | undefined;

  constructor() {
    this.use('register', RegistrationComponent);
    this.use('signin', AuthorizationComponent);
  }

  use(type: AuthStrategyType, component: AuthComponent): void {
    this.list.set(type, component);
  }

  get(type: AuthStrategyType): AuthComponent | undefined {
    return this.list.get(type);
  }
}
